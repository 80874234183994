import React from 'react';
import { graphql } from 'gatsby';
import MyHelmet from '../components/MyHelmet';
import ContactPageTemplate from '../templates/ContactPageTemplate';

function ContactPage({ data }) {
	const fm = data.directus.contactPage;
	return (
		<>
			<MyHelmet title={fm.title} description={fm.subheading} />
			<ContactPageTemplate
				heading={fm.heading}
				subheading={fm.subHeading}
				contactform={fm.contactForm}
				office={fm.office}
			/>
		</>
	);
}

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage {
    directus {
      contactPage {
        title
        heading
        subHeading
        office {
          tagline
          address
          phone
          location
          mapLocation
        }
        contactForm {
          heading
          description
          featuredImage {
            id
          }
        }
      }
    }
  }
`;
